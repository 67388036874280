import { useEffect, useState } from 'react'
import $ from 'jquery'

function Main() {
    const mobileYn = window.innerWidth < 900 ? true : false;
    const [chinaYn,setChinaYn] = useState(false); //중국에서 접속했는지 체크
    useEffect(() => {
        $.getJSON("https://ipinfo.io", function (data) {
            const ip = data.ip // 접속자 ip
            const hostname = data.hostname // 접속자 hostname
            const city = data.city // 접속자 도시
            const region = data.region // 접속자 지역
            const country = data.country // 접속자 국가
            const loc = data.loc // 접속 위도, 경도
            const org = data.org // ISP (인터넷 서비스 제공사업자)

            console.log(country);
            // 지역별 분기
            if (country == "CN") {
                setChinaYn(true);
            }
        });
    }, [])



    return (
        <div className='contents_wrap'>
            <div className="main m1" style={{ backgroundImage: "url('/img/back1" + (mobileYn ? "_m" : "") + ".jpg')" }}>
                <div className="main_copy">
                    Discover <br />
                    A<span className="kerning1"> </span>New <span className="kerning2">S</span>ensation
                </div>
                <div className="sub_copy">
                    CTC<span className="kerning"> </span>is<span className="kerning"> </span>a<span className="kerning"> </span>soft<span className="kerning"> </span>solution<span className="kerning"> </span>creat<span className="kerning2">o</span>r<span className="kerning"> </span><span className="kerning3">d</span><span className="kerning4">r</span><span className="kerning5">i</span>ving
                    material<span className="kerning"> </span>innovation.
                </div>
                <div className="col2_container">
                    <div className="main_img"><img src="/img/ic_plus.svg" /></div>
                    <div className="img_desc">Combining precision and ingenuity to transform materials, we advance the science of tactility to deliver optimal material performance.</div>
                </div>
            </div>
            {chinaYn && <div className='video_section'>
                <video width="100%" autoPlay={true} loop={true} muted={true}>
                    <source src="/video/video1.mp4" type="video/mp4" />
                </video>
            </div>}
           {!chinaYn&&<div className='video_section' style={{padding:"56.25% 0 0 0",position:"relative"}}>
            <iframe src="https://player.vimeo.com/video/730244585?background=1&autoplay=1&loop=1&muted=1&autopause=0&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>}
            <div className="main m2">
                <div className="spec_items">
                    <div className="spec_item">
                        <div className="spec_contents">
                            <div className="spec_title">Precision</div>
                            <div className="spec_desc">
                                We accurately align material, processing methods, and end use to create products of the highest quality.
                            </div>
                        </div>
                        <div className="spec_img">
                            <img src="/img/ic_main1.svg" />
                        </div>
                    </div>
                    <div className="spec_item">
                        <div className="spec_contents">
                            <div className="spec_title">Ingenuity</div>
                            <div className="spec_desc">
                                Harnessing material chemistry and processing systems as building blocks, we formulate novel solutions that innovate and disrupt.
                            </div>
                        </div>
                        <div className="spec_img">
                            <img src="/img/ic_main2.svg" />
                        </div>
                    </div>
                    <div className="spec_item">
                        <div className="spec_contents">
                            <div className="spec_title">Transformation</div>
                            <div className="spec_desc">
                                We convert materials into specialized products optimized for a myriad of applications.
                            </div>
                        </div>
                        <div className="spec_img">
                            <img src="/img/ic_main3.svg" />
                        </div>
                    </div>
                </div>
            </div>

            <div id="about" className="about a1" style={{ backgroundImage: "url('/img/back2" + (mobileYn ? "_m" : "") + ".jpg')" }}>
                <div className="page_title">Story</div>
                <div className="main_copy">
                    Driving Material <br />
                    Innovation
                </div>
                <div className="tag_items">
                    <div className="tag_item">Catalyze</div>
                    <div className="tag_item">Convert</div>
                    <div className="tag_item">Refine</div>
                </div>
                <div className="col2_container">
                    <div className="spec_items">
                        <div className="spec_item">
                            <img src="/img/ic_plus_g.svg" />
                            <div className="spec_title">
                                Soft <br />
                                Strong
                            </div>
                        </div>
                        <div className="spec_item">
                            <img src="/img/ic_plus_g.svg" />
                            <div className="spec_title">
                                Light <br />
                                Durable
                            </div>
                        </div>
                        <div className="spec_item">
                            <img src="/img/ic_plus_g.svg" />
                            <div className="spec_title">
                                Cushioning <br />
                                Responsive
                            </div>
                        </div>
                    </div>
                    <div className="spec_desc">
                        <div>
                            At CTC, we reimagine the potential of
                            materials to elevate the textures of life.
                            We ceaselessly research and develop the science of tactility. Through advanced compounding and processing methods,
                            we engineer materials that achieve a
                            balance of diverse properties.
                            <img src="/img/story_img.png"/>
                        </div>
                    </div>
                </div>
            </div>
            
            {chinaYn && <div className='video_section'>
                <video width="100%" autoPlay={true} loop={true} muted={true}>
                    <source src="/video/video2.mp4" type="video/mp4" />
                </video>
            </div>}

           {!chinaYn&&<div className='video_section' style={{padding:"56.25% 0 0 0",position:"relative"}}>
            <iframe src="https://player.vimeo.com/video/720165619?background=1&autoplay=1&loop=1&muted=1&autopause=0&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>}
            <div className="about a2">
                <div className="col2_container">
                    <div className="main_copy">
                        We specialize in <br />
                        soft foam development.
                    </div>
                    <img src="/img/ic_about.svg" />
                </div>
                <div className="spec_items">
                    <div className="spec_item">
                        <div className="spec_title">
                            <span className="spec_number">1</span>Chemical Foaming
                        </div>
                        <ul className="spec_contents">
                            <li>
                                Injection molding
                            </li>
                            <li>
                                Compression molding
                            </li>
                        </ul>
                    </div>
                    <div className="spec_item">
                        <div className="spec_title">
                            <span className="spec_number">2</span>Physical Injection Foaming
                        </div>
                        <ul className="spec_contents">
                            <li>
                                Autoclave supercritical physical
                                foaming
                            </li>
                            <li>
                                Injection physical foaming
                            </li>
                            <li>
                                Steam chest bead foaming
                            </li>
                        </ul>
                    </div>
                    <div className="spec_item">
                        <div className="spec_title">
                            <span className="spec_number">3</span>Compound Development
                        </div>
                        <ul className="spec_contents">
                            <li>
                                TPE, POE, EVA, SEBS, NR,
                                bio-based resins, PA, TPEE, TPU, PLA, PET, r-PET
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="solution" className="solution s1" style={{ backgroundImage: "url('/img/back3" + (mobileYn ? "_m" : "") + ".jpg')" }}>
                <div className="page_title">Solutions</div>
                <div className="main_copy">
                    Infinite Power <br />
                    of Soft Solutions
                </div>
                <div className="tag_items">
                    <div className="tag_item">Cushioning</div>
                    <div className="tag_item">Elastic</div>
                    <div className="tag_item">Versatile</div>
                </div>
                <div className="col2_container">
                    <div className="main_copy">
                        We see <br />
                        the infinite power <br />
                        of softness.
                    </div>
                    <div className="main_desc">
                        CTC specializes in soft solutions that make
                        the world a more comfortable, safe, and flexible place. Careful consideration of material end use underpins compounding
                        and processing techniques.
                    </div>
                </div>
            </div>
            {chinaYn&& <div className='video_section'>
                <video width="100%" autoPlay={true} loop={true} muted={true}>
                    <source src="/video/video3.mp4" type="video/mp4" />
                </video>
            </div>}
            {!chinaYn&&<div className='video_section' style={{padding:"56.25% 0 0 0",position:"relative"}}>
            <iframe src="https://player.vimeo.com/video/720167114?background=1&autoplay=1&loop=1&muted=1&autopause=0&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>}
            <div className="solution s2">

                <div className="col2_container">
                    <div className="main_copy">
                        Desired properties are regulated and optimized for end use.
                    </div>
                    <img src="/img/ic_solution1.svg" />
                </div>
                <div className="spec_items">
                    <div className="spec_item">
                        <div className="spec_header">
                            <div className="spec_title">Tenderate</div>
                            <div className="spec_subtitle">Chemical Foaming</div>
                            <img src="/img/logo1.png" className='logo1' />
                        </div>
                        <div className="spec_contents">
                            <ul>
                                <li>Closed cell soft foams</li>
                                <li>Asker C 20C - 70C</li>
                                <li>Density 0.12 - 0.45 g/cc</li>
                                <li>EU REACH compliance </li>
                            </ul>
                            <div className='link_items'>
                                <a className='link_item' href="https://tenderate.co.kr" target="_blank">tenderate.co.kr</a>
                                <a className='link_item' href="https://walknrest.com" target="_blank">walknrest.com</a>
                                <a className='link_item' href="https://tawntoe.com" target="_blank">tawntoe.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="spec_item">
                        <div className="spec_header">
                            <div className="spec_title">Whistling</div>
                            <div className="spec_subtitle">Physical Injection Foaming</div>
                            <img src="/img/logo2.png" className='logo2' />
                        </div>
                        <div className="spec_contents">
                            <ul>
                                <li>Sustainable foaming process</li>
                                <li>Less carbon footprint vs. Chemical foaming</li>
                                <li>Higher recyclability</li>
                                <li>Zero VOC</li>
                            </ul>
                            <div className='link_items'>
                                <a className='link_item' href="https://whistling.kr" target="_blank">whistling.kr</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='video_section' style={{padding:"56.25% 0 0 0",position:"relative"}}>
            <iframe src="https://player.vimeo.com/video/729526633?background=1&autoplay=1&loop=1&muted=1&autopause=0&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>
            <div id="contact" className="contact c1" style={{ backgroundImage: "url('/img/back4" + (mobileYn ? "_m" : "") + ".jpg')" }}>
           
                <div className="main_copy">
                    Get in touch
                </div>

            </div>
            <div className="contact c2">
                <div className="c2_left">
                <div className="address">
                    ctc@comtechchemical.kr<br />
                    +82 55 327 9611<br />
                    <br />
                    8-48 727 Bunseong-ro<br />
                    Gimhae Gyeongnam<br />
                    Busan South Korea 50827
                </div>
                <div className='career'>
                Career<br/>
                <a href="https://ctc.recruiter.im" target='_blank'>ctc.recruiter</a>
                </div>
                </div>
                <img src="/img/contact.jpg"/>

            </div>




        </div>
    )


}

export default Main;
